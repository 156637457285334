<template>
    <div class="page page-hp" @scroll="wheelTouch">

        <!--Section 1-->
        <div class="page-section section-first">
            <!-- <img class="main-first-img" :src='this.pathprod+"images/home/harmony-hp-img-1.jpg" alt="Harmony-hp-img-1"> -->
            <div class="page-section-col">
                <div class="logo-bandeau-white">
                    <div class="img-block-brush">
                    <img :src='this.$pathprod+"images/home/rhapsody-hp-brush-1.png"' alt="brush 1" class="logo-brush" width="239" height="129">
                    </div>
                    <div class="logo-block">
                    <h1 style="display: none;">{{$t('nameSite')}}</h1>
                    <img :src='this.$pathprod+"images/logo-rhapsody.svg"' alt="Logo-harmony" width="175" height="30">
                    <h2 class="logo-subtext">{{$t('sloganSite')}}</h2>
                    </div>
                </div>
            </div>

            <div class="scroll-right">
            <a href="#" class="btn btn-white btn-scroll" v-on:click="scrollToNext">  
                <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1.20101L10.8995 11.1005L1 21" stroke="#6CB0BD" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1.20101L10.8995 11.1005L1 21" stroke="#6CB0BD" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </a>
            </div>
        </div>
        <!--/Section 1-->

        <!--Section chiffre key-->
        <div class="page-section section-key-number" :class="{'increment': increment}">
            <div class="page-section-col">
            <div class="block-key-number" :class="{'stopP': stopP}">
                <div class="key-number-list">
                    <div class="key-number-item">
                    <p class="key-number">
                      <!-- <span class="number-to-animate" data-number="44000">44 000</span>  -->
                    <number
                      animationPaused
                      ref="number1"
                      :from="100"
                      :to="43500"
                      :format="theFormat"
                      :duration="1"
                      easing="Power1.easeOut"/>
                    m<span class="m2">2</span></p>
                    <p class="key-desc">{{$t('chiffreKey')['0']}}</p>
                    </div>

                    <div class="key-number-item">
                    <p class="key-number">
                      <!-- <span class="number-to-animate" data-number="4000">4 000</span>  -->
                      <number
                        animationPaused
                        ref="number2"
                        :from="0"
                        :to="4500"
                        :format="theFormat"
                        :duration="1"
                        easing="Power1.easeOut"/>
                      <br>à 
                      <!-- <span class="number-to-animate" data-number="5900">5 900</span>  -->
                      <number
                        animationPaused
                        ref="number3"
                        :from="0"
                        :to="5500"
                        :format="theFormat"
                        :duration="1"
                        easing="Power1.easeOut"/>
                      m<span class="m2">2</span></p>
                    <p class="key-desc">{{$t('chiffreKey')['1']}}</p>
                    </div>

                    <div class="key-number-item">
                    <p class="key-number">
                      <!-- <span class="number-to-animate" data-number="7200">7 200</span>  -->
                      <number
                        animationPaused
                        ref="number4"
                        :from="0"
                        :to="7200"
                        :format="theFormat"
                        :duration="1"
                        easing="Power1.easeOut"/>
                      m<span class="m2">2</span></p>
                    <p class="key-desc">{{$t('chiffreKey')['2']}} <br> {{$t('chiffreKey')['3']}} m<span class="m2">2</span> {{$t('chiffreKey')['4']}}</p>
                    </div>

                    <div class="key-number-item">
                    <p class="key-number">
                      <!-- <span class="number-to-animate" data-number="3715">3 715</span> -->
                      <number
                        animationPaused
                        ref="number5"
                        :from="0"
                        :to="3715"
                        :format="theFormat"
                        :duration="1"
                        easing="Power1.easeOut"/>  
                    </p>
                    <p class="key-desc">{{$t('chiffreKey')['5']}}</p>
                    </div>

                    <div class="key-number-item">
                    <p class="key-number">
                      <!-- <span class="number-to-animate" data-number="310">310</span>  -->
                      <number
                        animationPaused
                        ref="number6"
                        :from="0"
                        :to="310"
                        :format="theFormat"
                        :duration="1"
                        easing="Power1.easeOut"/>  
                    m<span class="m2">2</span></p>
                    <p class="key-desc">{{$t('chiffreKey')['6']}}</p>
                    </div>

                    <div class="key-number-item">
                    <p class="key-number">
                    <!-- <span class="number-to-animate" data-number="5000">5 000</span>  -->
                    <number
                        animationPaused
                        ref="number7"
                        :from="0"
                        :to="5000"
                        :format="theFormat"
                        :duration="1"
                        easing="Power1.easeOut"/> 
                    m<span class="m2">2</span></p>
                    <p class="key-desc">{{$t('chiffreKey')['7']}} <span class="key-desc-mini ls-04">{{$t('chiffreKey')['8']}} <br class="only-mb"> {{$t('chiffreKey')['9']}} <br> {{$t('chiffreKey')['10']}}</span></p>
                    </div>

                    <div class="key-number-item">
                    <p class="key-number-text"><span>373</span> {{$t('chiffreKey')['11']}}</p>
                    <p class="key-desc">{{$t('chiffreKey')['12']}}</p>
                    </div>

                    <div class="key-number-item">
                    <p class="key-desc">{{$t('chiffreKey')['13']}} </p>
                    <p class="key-number-text"><span>780</span> m<span class="m2">2</span> <br>{{$t('chiffreKey')['14']}}</p>
                    </div>
                </div>
                <div class="block-brush-mobile" :class="{'showBrush': showBrushK}">
                <img  :src='this.$pathprod+"images/home/rhapsody-hp-brush-2.png"' alt="harmony-hp-brush-2" class="brush" width="256" height="1000">
                </div>
            </div>
            <div class="block-gold"></div>
            </div>
            <div class="page-section-col">
            <figure>
              <picture class="loadTemp"> 
                <source srcset="" :dta-srcset='this.$pathprod+"images/home/rhapsody-hp-img-2-retina.webp"' type="image/webp"> 
                <img src="" :dta-src='this.$pathprod+"images/home/rhapsody-hp-img-2.jpg"' alt="harmony-hp-img-2" class="bg-key-harmony" width="710" height="900">
              </picture>
            </figure>
            </div>
        </div>
        <!--Section chiffre key-->

        <!--Section 3-->
        <div class="page-section section-home-content">
            <div class="img-block-brush"  :class="{'showBrush': showBrush}">
            <img :src='this.$pathprod+"images/home/rhapsody-hp-brush-4.png"' alt="harmony-hp-brush-4" class="mask-brush" width="422" height="546">
            <div class="mask-brush-mobile"></div>
            </div>
            <div class="img-block-brush-2"  :class="{'showBrush': showBrush}">
            <img :src='this.$pathprod+"images/home/rhapsody-hp-brush-4.png"' alt="harmony-hp-brush-4" class="mask-brush-2" width="422" height="546">
            <div class="mask-brush2-mobile"></div>
            </div>
            <div class="page-section-col">
            </div>
            <div class="page-section-col">
            <div class="home-content">
                <div class="home-content-top">
                <div class="logo-block">
                    <img :src='this.$pathprod+"images/home/rhapsody-hp-brush-3.png"' alt="harmony-hp-brush" class="brush" width="142" height="76">
                    <img :src='this.$pathprod+"images/home/logo-white.svg"' alt="logo-harmony-white" class="logo" width="177" height="30">
                </div>
                <div class="home-top-right">
                    <span class="home-text">mouvement <br> d’ensemble</span>
                </div>
                </div>
                <div class="home-content-bottom">
                <div class="home-content-text-block">
                    <p class="home-content-text">
                    Saint-Ouen. Derrière ses lignes horizontales, transparentes et végétales, RHAPSODY est un chorus ouvert aux improvisations,
                    aux rencontres, aux partages. Un lieu de libre expression, de performance créative, de pollinisation qui puise 
                    son inspiration des anciens Docks.
                    </p>

                    <p class="home-content-text">
                    RHAPSODY est urbain, collectif, nourricier. C’est un réservoir d’intelligences collaboratives qui profitent de services attentionnés et personnalisés, d’espaces intuitifs et fonctionnels qui polarisent. La place, les kiosques, les studios, le club, le foyer...
                    </p>
                </div>
                <div class="home-content-text-block">
                    <p class="home-content-text">
                    RHAPSODY est un signe qui invite ; une agora réinventée qui convie à prendre part, à contribuer, à s’impliquer.
                    </p>

                    <p class="home-content-text">
                    RHAPSODY, lieu d’urbanité et de virtuosité rassemblées.
                    </p>
                </div>
                </div>
            </div>
            </div>
        </div>
        <!--/Section 3-->
        
    </div>
</template>

<script>

export default {
  name: 'Homemobile',
  props: {
    msg: String,
  },
  metaInfo() {
      return { 
          title: "Rhapsody - Accueil",
          meta: [
              { name: 'description', content:  'Saint-Ouen. Derrière ses lignes horizontales, RHAPSODY est un chorus ouvert aux improvisations. 44 000 m2 de bureaux et services.'},
              { property: 'og:title', content: "Rhapsody - Accueil"},
              { property: 'og:site_name', content: 'Rhapsody'},
              { property: 'og:type', content: 'website'},    
          ]
      }
  },
  data(){
    return{
      scroll: null,
      increment: false,
      showBrushK:false,
      showBrush:false,
      varPropagation: false,
      stopP: false,
      valTop: 0,
      valBottom: 0,
      oldValue: 0,
      newValue: 0,
      hooperSettings: {
        itemsToShow: 1,
        transition: 800,
        breakpoints: {
          320: {
              itemsToShow: 1,
          },
          992: {
              itemsToShow: 1,
          }
        }
      },
    }
  },
  
  methods:{

    theFormat(number) {
      return this.numberWithSpaces(number.toFixed(0));
    },

    playAnimation() {
        this.$refs.number1.play()
        this.$refs.number2.play()
        this.$refs.number3.play()
        this.$refs.number4.play()
        this.$refs.number5.play()
        this.$refs.number6.play()
        this.$refs.number7.play()
    },

    numberWithSpaces(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },

    addFormatNumber(){
      let item = document.querySelectorAll('.number-to-animate');
      for(let i = 0; i < item.length; i++){
          item[i].innerText = this.numberWithSpaces(item[i].innerText);
      }
    },

    scrollToNext(e){
      e.preventDefault();
      let pagehp = document.querySelector('.page-hp');
      let container = this.$el.querySelector(".section-key-number");
      pagehp.scrollTo({
        top: 0,
        left: container.offsetLeft,
        behavior: 'smooth'
      });

      this.playAnimation()
      this.increment = true;
      this.beginAnimationBrushKeyNumber();
    },

    wheelTouch(){
      const el = document.querySelector('.page-hp');
      const el2 = document.querySelector('.section-key-number');
      const el3 = document.querySelector('.section-home-content');
        if(this.increment == false){
          if(el.scrollLeft >=  el2.offsetLeft - 200){
            this.playAnimation();
            this.increment = true;
            this.beginAnimationBrushKeyNumber();
          }
        }
        else if(this.showBrush == false){
          if(el.scrollLeft >=  el3.offsetLeft - 200){
            this.beginAnimationBrushLastSection();
          }
        }
    },

    /*Fonction beginAnimationBrush pour la section 3*/
     beginAnimationBrushKeyNumber(){
       this.showBrushK = true;
     },

    /*Fonction beginAnimationBrush pour la section 3*/
     beginAnimationBrushLastSection(){
       this.showBrush = true;
     },

     stopBlockPropagation(e){
        e.stopPropagation();
     },
  },
  mounted () {
    document.body.classList.remove('removeHiddenOverflow');
    // setTimeout(function(){
    //   document.querySelector('.logo-bandeau-white').classList.add('slide-in-top')
    // },200)

    /*Diffère tous les images*/
    setTimeout(function(){
        let loadImgSource = document.querySelectorAll('.loadTemp source');
        let loadImgImg = document.querySelectorAll('.loadTemp img');
        for(let i = 0; i < loadImgSource.length; i++){
            loadImgSource[i].setAttribute('srcset',loadImgSource[i].getAttribute('dta-srcset'));
            loadImgImg[i].setAttribute('src',loadImgImg[i].getAttribute('dta-src'));
            loadImgImg[i].setAttribute('srcset',loadImgImg[i].getAttribute('dta-srcset'));
        }
    }.bind(this), 1200);
  },
}
</script>

<style scoped>

</style>